import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {ApiError} from '../../api/basicTypes/ApiError';
import {queryClient} from '../../app/App';
import {
  UseShortMutationOptions,
  UseShortQueryOptions,
} from '../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../globalNotification/errorHandler';

export const GetAuthDataKey = 'local/auth/data';

const GetAuthCreateDataKey = 'local/auth/create';
const GetAuthUpdateDataKey = 'local/auth/update';
const GetAuthDeleteDataKey = 'local/auth/delete';

export interface AuthData {
  id: string;
  name: string;
  phone?: string;
  near?: string;
  isLastCompleted?: boolean;
}

export function useGameAuthData(
  options?: UseShortQueryOptions<AuthData, AxiosError<ApiError>>,
): UseQueryResult<AuthData, AxiosError> {
  return useQuery(
    [GetAuthDataKey],
    (): AuthData => {
      const data = queryClient.getQueryData<AuthData>([GetAuthDataKey]);
      if (data) return data;
      throw new Error(`Auth data is not found`);
    },
    {
      cacheTime: 1000 * 60 * 60 * 24 * 30,
      retry: 0,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export const useSetGameAuthData = (
  options?: UseShortMutationOptions<void, AxiosError<ApiError>, AuthData>,
) => {
  return useMutation<void, AxiosError<ApiError>, AuthData>(
    [GetAuthCreateDataKey],
    async (data) => {
      queryClient.setQueryData([GetAuthDataKey], data);
    },
    {
      ...options,
    },
  );
};

export const useUpdateGameAuthData = (
  options?: UseShortMutationOptions<
    void,
    AxiosError<ApiError>,
    Partial<Omit<AuthData, 'id'>>
  >,
) => {
  return useMutation<void, AxiosError<ApiError>, Partial<Omit<AuthData, 'id'>>>(
    [GetAuthUpdateDataKey],
    async (data) => {
      queryClient.setQueryData<AuthData>([GetAuthDataKey], (oldData) => {
        if (!oldData) throw Error('Cant update! No saved data found');
        return {
          ...data,
          ...oldData,
        };
      });
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};

export const useRemoveGameAuthData = (
  options?: UseShortMutationOptions<void, AxiosError<ApiError>, void>,
) => {
  return useMutation<void, AxiosError<ApiError>, void>(
    [GetAuthDeleteDataKey],
    async () => {
      queryClient.removeQueries([GetAuthDataKey]);
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};
