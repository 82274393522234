import {animated, config, useSpring} from '@react-spring/three';
import {Canvas} from '@react-three/fiber';
import {useRef, useState, useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Vector3, Euler, BackSide} from 'three';
import {useCheckQuest} from '../../../api/hereIntegration/HereIntegration';
import {
  useGameAuthData,
  useUpdateGameAuthData,
} from '../../../services/gameAuth/AuthProvider';
import {BodyModel} from '../../../types/models/BodyModel';
import {HeadModel} from '../../../types/models/HeadModel';
import {HereIntegrationSave} from '../../../types/save/HereIntegrationSave';
import Button, {ButtonStyle} from '../../form/button/Button';
import Input from '../../form/input/Input';
import HitEffect, {HitEffectRefProps} from '../effects/HitEffect';
import GameSound, {GameSoundRefProps} from '../effects/Sound';
import AndroidPhone from '../mechanics/androidEmulator/AndroidPhone';
import GamePopup from '../mechanics/InformingSliderView/GamePopup';
import {
  ButtonsLayout,
  WidnowSize,
} from '../mechanics/InformingSliderView/GamePopupElement';
import InformingView from '../mechanics/InformingSliderView/InformingView';
import Character, {CharacterRefProps} from '../models/Character';
import Enimy, {EnimyRefProps} from '../models/Enimy';
import Fireball, {FireballRefProps} from '../models/Fireball';
import BattleInfo, {BattleInfoRefProps, Orientation} from '../ui/BattleInfo';
import BlackBackground from '../utils/BlackBackground';
import {IntegrationSceneProps} from '../utils/IntegrationScene';

interface Scene1AndroidProps
  extends IntegrationSceneProps<HereIntegrationSave> {
  headModel?: HeadModel;
  bodyModel?: BodyModel;
}

interface AndroidSignUp {
  phone: string;
}

interface ExistingNearSignUp {
  account: string;
}

const HerePart1Android: React.FC<Scene1AndroidProps> = ({
  headModel,
  bodyModel,
  save,
  switchScene,
  prefixId = 'scene_not_set',
}) => {
  const getAuth = useGameAuthData();
  const updateAuth = useUpdateGameAuthData();

  const {t} = useTranslation(['herePart1Android', 'universal']);

  const fireballSoundRef = useRef<GameSoundRefProps>(null);
  const successSound = useRef<GameSoundRefProps>(null);
  const wrongSound = useRef<GameSoundRefProps>(null);

  const playerRef =
    useRef<CharacterRefProps>() as React.MutableRefObject<CharacterRefProps>;

  const enimyRef =
    useRef<EnimyRefProps>() as React.MutableRefObject<EnimyRefProps>;

  const hitPlayerEffectRef =
    useRef<HitEffectRefProps>() as React.MutableRefObject<HitEffectRefProps>;

  const hitEnimyEffectRef =
    useRef<HitEffectRefProps>() as React.MutableRefObject<HitEffectRefProps>;

  const fireballAnimation =
    useRef<FireballRefProps>() as React.MutableRefObject<FireballRefProps>;

  const PlayerBattleInfo =
    useRef<BattleInfoRefProps>() as React.MutableRefObject<BattleInfoRefProps>;
  const EnimyBattleInfo =
    useRef<BattleInfoRefProps>() as React.MutableRefObject<BattleInfoRefProps>;

  const [isQuestionsHidden, setIsQuestionsHidden] = useState(false);
  const [currectStage, setCurrectStage] = useState(-1);

  const [isLaunched, setIsLaunched] = useState(false);
  const [isFireballVisible, setIsFireballVisible] = useState(false);

  const [isFailedChallange, setIsFailedChallange] = useState(false);

  const {fireballPosition} = useSpring({
    fireballPosition: !isLaunched ? -2.5 : 3,
    config: config.stiff,
  });

  // {
  //   register,
  //   handleSubmit,
  //   formState: {errors},
  // }
  const androidForm = useForm<AndroidSignUp>({
    defaultValues: {
      phone: getAuth.data?.phone,
    },
  });
  const iosForm = useForm<ExistingNearSignUp>();

  const castSpellAnimation = useCallback(() => {
    setIsFireballVisible(true);
    fireballAnimation.current?.spawn();
    fireballSoundRef.current?.play();
    setTimeout(() => {
      setIsLaunched(true);
    }, 2500);
    setTimeout(() => {
      setIsFireballVisible(false);
      setIsLaunched(false);

      enimyRef.current?.getDamage();
      hitEnimyEffectRef.current?.hitAnimation();
    }, 2800);
  }, [enimyRef, fireballAnimation, hitEnimyEffectRef]);

  const onQuestionAnswered = useCallback(
    (isCorrect: boolean) => {
      setIsQuestionsHidden(true);

      if (isCorrect) {
        successSound.current?.play();
      } else {
        wrongSound.current?.play();
      }

      setTimeout(() => {
        if (isCorrect) {
          playerRef.current?.attack();
          castSpellAnimation();

          PlayerBattleInfo.current?.changeEnergy(-1);

          setTimeout(() => {
            EnimyBattleInfo.current?.changeHealth(-1);
          }, 3000);

          setTimeout(() => {
            setIsQuestionsHidden(false);
          }, 4000);
        } else {
          enimyRef.current?.attack();

          EnimyBattleInfo.current?.changeEnergy(-1);

          setTimeout(() => {
            playerRef.current?.getDamage();
            hitPlayerEffectRef.current?.hitAnimation();
            PlayerBattleInfo.current.changeHealth(-1);

            setTimeout(() => {
              setIsQuestionsHidden(false);
            }, 800);
          }, 400);
        }
      }, 800);
    },
    [
      EnimyBattleInfo,
      castSpellAnimation,
      enimyRef,
      hitPlayerEffectRef,
      playerRef,
    ],
  );

  const setStageWithDelay = useCallback((stage: number, delay: number) => {
    setTimeout(() => {
      setCurrectStage(stage);
    }, delay);
  }, []);

  const onAndroidSignUpSubmit = useCallback(
    (data: AndroidSignUp) => {
      save({
        android: {
          sendToPhone: data.phone,
        },
      });
      updateAuth.mutate({
        phone: data.phone,
      });

      setCurrectStage(5);
    },
    [updateAuth, save],
  );

  const checkNearId = useCheckQuest({
    onSuccess: (data, variables) => {
      if (data.test_1) {
        save({
          android: {
            sendToIosNear: variables.near_account_id,
          },
        });
        setCurrectStage(5);
      } else {
        setIsFailedChallange(true);
      }
    },
  });

  const onIosSignUpSubmit = useCallback(
    (data: ExistingNearSignUp) => {
      checkNearId.mutate({
        near_account_id: data.account,
      });
    },
    [checkNearId],
  );

  useEffect(() => {
    setStageWithDelay(0, 1000);
  }, [setStageWithDelay]);

  const [isCurrentStageCorrect, setIsCurrentStageCorrect] = useState(true);

  return (
    <div className="relative w-full h-full">
      <div
        style={{
          backgroundImage: 'url("/backgrounds/2.jpg")',
          backgroundSize: 'cover',
        }}
        className="absolute top-0 left-0 w-full h-full"
      >
        <Canvas
          shadows
          camera={{rotation: [-0.3, 0, 0], position: [0, 2.3, 5], fov: 60}}
        >
          <GameSound
            ref={fireballSoundRef}
            src="https://storage.googleapis.com/stemmy-integrations/sfx/Fireball.wav"
          />

          <GameSound
            ref={successSound}
            src="https://storage.googleapis.com/stemmy-integrations/sfx/answerSuccess.wav"
          />
          <GameSound
            ref={wrongSound}
            src="https://storage.googleapis.com/stemmy-integrations/sfx/answerWrong.mp3"
          />

          <GameSound
            src="https://storage.googleapis.com/stemmy-integrations/audio/bg_normal.mp3"
            autoplay
            loop
          />

          <ambientLight color="#fff" intensity={0.3} />
          <directionalLight
            position={[0, 15, 10]}
            intensity={0.5}
            castShadow
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
          />
          <HitEffect
            ref={hitPlayerEffectRef}
            position={new Vector3(-2.8, 1, 1)}
            audioSrc="https://storage.googleapis.com/stemmy-integrations/sfx/CombatPunch2.wav"
          />
          <HitEffect
            ref={hitEnimyEffectRef}
            position={new Vector3(3, 1, 1)}
            audioSrc="https://storage.googleapis.com/stemmy-integrations/sfx/Punch1.wav"
          />
          <Character
            ref={playerRef}
            scale={new Vector3(1, 1, 1)}
            position={new Vector3(-3.8, -1, 0)}
            rotation={new Euler(0, 1.5, 0)}
            headModel={headModel}
            bodyModel={bodyModel}
            // weaponModel={swordModel}
          />
          <Enimy
            ref={enimyRef}
            scale={new Vector3(1.5, 1.5, 1.5)}
            position={new Vector3(3.8, -1, 0)}
            rotation={new Euler(0, -1.4, 0)}
          />

          {/* to - (3); from - (-2.5) */}
          <animated.group
            position-x={fireballPosition}
            visible={isFireballVisible}
          >
            <Fireball ref={fireballAnimation} />
          </animated.group>

          <mesh
            position={[0, -1, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            receiveShadow
          >
            <planeBufferGeometry attach="geometry" args={[25, 15]} />
            <shadowMaterial
              attach="material"
              side={BackSide}
              color="#000"
              opacity={0.2}
              transparent
            />
          </mesh>
        </Canvas>
      </div>

      <BattleInfo
        avatarSrc="/stemmy/p_head.png"
        orientation={Orientation.LEFT}
        totalHealth={3}
        totalEnergy={10}
        properties={[]}
        ref={PlayerBattleInfo}
      />
      <BattleInfo
        avatarSrc="/stemmy/e_head.png"
        orientation={Orientation.RIGHT}
        totalHealth={3}
        totalEnergy={10}
        properties={[]}
        ref={EnimyBattleInfo}
      />

      {/* ============================================== Quests ============================================== */}
      {isFailedChallange && (
        <BlackBackground className="z-20">
          <div className="absolute z-30 w-full h-full flex flex-col justify-center items-center">
            <InformingView>
              <div className="flex flex-col gap-3 my-2">
                <div className="font-bold text-game-lg">
                  {t('we-couldnt-find-your-near-account', {ns: 'universal'})}
                </div>
                <div>
                  {t(
                    'enter-the-username-you-have-provided-in-process-of-here-wallet-installation-it-will-usually-look-like-myaccount-near',
                    {ns: 'universal'},
                  )}
                </div>
                <Button onClick={() => setIsFailedChallange(false)}>
                  {t('try-again-0', {ns: 'universal'})}
                </Button>
              </div>
            </InformingView>
          </div>
        </BlackBackground>
      )}

      <GamePopup
        prefixId={prefixId}
        id="intro"
        active={currectStage == 0}
        onFinish={() => setCurrectStage(1)}
        elements={[
          {
            id: 'intro',
            conversationImage: '/stemmy/e_head.png',
            title: t('hmm'),

            externalbuttons: [
              {
                id: 'next',
                title: t('lets-go-0'),
                buttonStyle: ButtonStyle.Purple,
                className: 'text-game-xl',
                isNextOnClick: true,
              },
            ],
          },
        ]}
      />

      <GamePopup
        prefixId={prefixId}
        id="test1"
        active={currectStage == 1}
        onFinish={() => setCurrectStage(2)}
        tempHidden={isQuestionsHidden}
        elements={[
          {
            id: 'task1_intro',
            conversationImage: '/stemmy/e_head.png',
            title: t('task-1'),
            text: t('how-can-you-send-money-by-phone-number'),
            innerButtons: [
              {
                id: 'next',
                title: t('start'),
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(true),
              },
            ],
            windowSize: WidnowSize.Big,
          },
          {
            id: 'task1_1',
            conversationImage: '/stemmy/e_head.png',
            title: t('how-can-you-send-money-by-phone-number-0'),
            text: t('step-1-4'),
            buttonsLayout: ButtonsLayout.TWO_COLUMNS,
            innerButtons: [
              {
                id: '1',
                title: '1',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
              {
                id: '2',
                title: '2',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
              {
                id: '3',
                title: '3',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
              {
                id: '4',
                title: '4',
                className: 'w-full',
                isCoorrectAnswer: true,
                isNextOnClick: true,
                clickDelay: 1000,
              },
            ],
            verticalElement: <AndroidPhone image="/here/android/1.1.jpg" />,
            windowSize: WidnowSize.Fullscreen,
          },
          {
            id: 'task1_2',
            conversationImage: '/stemmy/e_head.png',
            title: t('how-can-you-send-money-by-phone-number-0'),
            text: t('step-2-4'),
            innerButtons: [
              {
                id: '1',
                title: '1',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
              {
                id: '2',
                title: '2',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
              {
                id: '3',
                title: '3',
                className: 'w-full',
                isCoorrectAnswer: true,
                isNextOnClick: true,
                clickDelay: 1000,
              },
            ],
            verticalElement: <AndroidPhone image="/here/android/1.2.jpg" />,
            windowSize: WidnowSize.Fullscreen,
          },
          {
            id: 'tast1_3',
            conversationImage: '/stemmy/e_head.png',
            title: t('how-can-you-send-money-by-phone-number-0'),
            text: t('step-3-4'),
            innerButtons: [
              {
                id: '1',
                title: '1',
                className: 'w-full',
                isCoorrectAnswer: true,
                isNextOnClick: true,
                clickDelay: 1000,
              },
              {
                id: '2',
                title: '2',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
            ],
            verticalElement: <AndroidPhone image="/here/android/1.3.jpg" />,
            windowSize: WidnowSize.Fullscreen,
          },
          {
            id: 'task1_done',
            conversationImage: '/stemmy/e_head.png',
            title: t('how-can-you-send-money-by-phone-number-0'),
            text: t('done'),
            verticalElement: <AndroidPhone image="/here/android/1.4.jpg" />,
            innerButtons: [
              {
                id: 'next',
                title: t('Next'),
                className: 'w-full',
                isNextOnClick: true,
                onClick: () => onQuestionAnswered(isCurrentStageCorrect),
              },
            ],
            windowSize: WidnowSize.Fullscreen,
          },
        ]}
      />

      <GamePopup
        prefixId={prefixId}
        id="test2"
        active={currectStage == 2}
        tempHidden={isQuestionsHidden}
        onFinish={() => setCurrectStage(3)}
        elements={[
          {
            id: 'task2_intro',
            conversationImage: '/stemmy/e_head.png',
            title: t('task-2'),
            text: t('how-to-start-earning-a-lifetime-passive-income'),
            innerButtons: [
              {
                id: 'next',
                title: t('start'),
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(true),
              },
            ],
            windowSize: WidnowSize.Big,
          },
          {
            id: 'task2_1',
            conversationImage: '/stemmy/e_head.png',
            title: t('how-to-start-earning-a-lifetime-passive-income'),
            text: t('step-1-4'),
            buttonsLayout: ButtonsLayout.TWO_COLUMNS,
            innerButtons: [
              {
                id: '1',
                title: '1',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
              {
                id: '2',
                title: '2',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
              {
                id: '3',
                title: '3',
                className: 'w-full',
                isCoorrectAnswer: true,
                isNextOnClick: true,
                clickDelay: 1000,
              },
              {
                id: '4',
                title: '4',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
            ],
            verticalElement: <AndroidPhone image="/here/android/2.1.jpg" />,
            windowSize: WidnowSize.Fullscreen,
          },
          {
            id: 'task2_2',
            conversationImage: '/stemmy/e_head.png',
            title: t('how-to-start-earning-a-lifetime-passive-income'),
            text: t('step-2-4'),
            innerButtons: [
              {
                id: '1',
                title: '1',
                className: 'w-full',
                isCoorrectAnswer: true,
                isNextOnClick: true,
                clickDelay: 1000,
              },
              {
                id: '2',
                title: '2',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
            ],
            verticalElement: <AndroidPhone image="/here/android/2.2.jpg" />,
            windowSize: WidnowSize.Fullscreen,
          },
          {
            id: 'task2_3',
            conversationImage: '/stemmy/e_head.png',
            title: t('how-to-start-earning-a-lifetime-passive-income'),
            text: t('step-3-4'),
            innerButtons: [
              {
                id: '1',
                title: '1',
                className: 'w-full',
                isCoorrectAnswer: true,
                isNextOnClick: true,
                clickDelay: 1000,
              },
              {
                id: '2',
                title: '2',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
            ],
            verticalElement: <AndroidPhone image="/here/android/2.3.jpg" />,
            windowSize: WidnowSize.Fullscreen,
          },
          {
            id: 'task2_done',
            conversationImage: '/stemmy/e_head.png',
            title: t('how-to-start-earning-a-lifetime-passive-income'),
            text: t('done'),
            innerButtons: [
              {
                id: 'next',
                title: t('Next'),
                className: 'w-full',
                isNextOnClick: true,
                onClick: () => onQuestionAnswered(isCurrentStageCorrect),
              },
            ],
            verticalElement: <AndroidPhone image="/here/android/2.4.jpg" />,
            windowSize: WidnowSize.Fullscreen,
          },
        ]}
      />

      <GamePopup
        prefixId={prefixId}
        id="test3"
        active={currectStage == 3}
        onFinish={() => setCurrectStage(4)}
        tempHidden={isQuestionsHidden}
        elements={[
          {
            id: 'task3_intro',
            conversationImage: '/stemmy/e_head.png',
            title: t('task-3'),
            text: t(
              'how-can-you-buy-items-at-here-without-a-card-and-verification',
            ),
            innerButtons: [
              {
                id: 'next',
                title: t('start'),
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(true),
              },
            ],
            windowSize: WidnowSize.Big,
          },
          {
            id: 'task3_1',
            conversationImage: '/stemmy/e_head.png',
            title: t(
              'how-can-you-buy-items-at-here-without-a-card-and-verification',
            ),
            text: t('step-1-4'),
            buttonsLayout: ButtonsLayout.TWO_COLUMNS,
            innerButtons: [
              {
                id: '1',
                title: '1',
                className: 'w-full',
                isCoorrectAnswer: true,
                isNextOnClick: true,
                clickDelay: 1000,
              },
              {
                id: '2',
                title: '2',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
              {
                id: '3',
                title: '3',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
              {
                id: '4',
                title: '4',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
            ],
            verticalElement: <AndroidPhone image="/here/android/3.1.jpg" />,
            windowSize: WidnowSize.Fullscreen,
          },
          {
            id: 'task3_2',
            conversationImage: '/stemmy/e_head.png',
            title: t(
              'how-can-you-buy-items-at-here-without-a-card-and-verification',
            ),
            text: t('step-2-4'),
            innerButtons: [
              {
                id: '1',
                title: '1',
                className: 'w-full',
                isCoorrectAnswer: true,
                isNextOnClick: true,
                clickDelay: 1000,
              },
              {
                id: '2',
                title: '2',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
              {
                id: '3',
                title: '3',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
            ],
            verticalElement: <AndroidPhone image="/here/android/3.2.jpg" />,
            windowSize: WidnowSize.Fullscreen,
          },
          {
            id: 'task3_3',
            conversationImage: '/stemmy/e_head.png',
            title: t(
              'how-can-you-buy-items-at-here-without-a-card-and-verification',
            ),
            text: t('step-3-4'),
            innerButtons: [
              {
                id: '1',
                title: '1',
                className: 'w-full',
                isCoorrectAnswer: false,
                isNextOnClick: true,
                onClick: () => setIsCurrentStageCorrect(false),
                clickDelay: 1000,
              },
              {
                id: '2',
                title: '2',
                className: 'w-full',
                isCoorrectAnswer: true,
                isNextOnClick: true,
                clickDelay: 1000,
              },
            ],
            verticalElement: <AndroidPhone image="/here/android/3.3.jpg" />,
            windowSize: WidnowSize.Fullscreen,
          },
          {
            id: 'task3_done',
            conversationImage: '/stemmy/e_head.png',
            title: t(
              'how-can-you-buy-items-at-here-without-a-card-and-verification',
            ),
            text: t('done'),
            innerButtons: [
              {
                id: 'next',
                title: t('finish-him'),
                className: 'w-full',
                isNextOnClick: true,
                onClick: () => onQuestionAnswered(isCurrentStageCorrect),
              },
            ],
            verticalElement: <AndroidPhone image="/here/android/3.4.jpg" />,
            windowSize: WidnowSize.Fullscreen,
          },
        ]}
      />

      <GamePopup
        prefixId={prefixId}
        id="get_rewards"
        tempHidden={isQuestionsHidden}
        active={currectStage == 4}
        elements={[
          {
            id: 'get_rewards',
            conversationImage: '/stemmy/e_head.png',
            title: t('how-can-we-send-you-the-winnings'),

            externalbuttons: [
              {
                id: 'ios',
                title: t('get-winnings-at-here-wallet-on-ios'),
                buttonStyle: ButtonStyle.Purple,
                className: 'text-game-xl',
                onClick: () => setCurrectStage(10),
              },
              {
                id: 'android',
                title: t('get-added-to-the-android-waiting-list'),
                buttonStyle: ButtonStyle.Purple,
                className: 'text-game-xl',
                isNextOnClick: true,
              },
            ],
          },
          {
            id: 'android_form',
            conversationImage: '/stemmy/e_head.png',
            title: t(
              'to-which-phone-number-to-send-an-invitation-after-publishing-the-android-version',
            ),

            horizontalElement: (
              <form
                className="flex w-full mt-2 gap-2"
                onSubmit={androidForm.handleSubmit(onAndroidSignUpSubmit)}
              >
                <Input
                  className="flex-1"
                  registration={{
                    register: androidForm.register,
                    errors: androidForm.formState.errors,
                  }}
                  name="phone"
                  type="phone"
                  options={{
                    required: t('please-enter-your-phone-number', {
                      ns: 'universal',
                    }),
                    pattern: {
                      value: /^\+[1-9\(\)]\d{1,16}$/,
                      message: t('enter-in-format-88888888', {ns: 'universal'}),
                    },
                  }}
                />
                <Button type="submit">{t('ok-2')}</Button>
              </form>
            ),

            innerButtons: [
              {
                id: 'back',
                title: t('Back'),
                isBackOnClick: true,
              },
            ],
          },
        ]}
      />

      <GamePopup
        prefixId={prefixId}
        id="near_form"
        active={currectStage == 10}
        elements={[
          {
            id: 'near_form',
            conversationImage: '/stemmy/e_head.png',
            title: t('what-is-your-username-for-the-prize-to-be-sent-to'),

            horizontalElement: (
              <form
                className="flex w-full mt-2 gap-2"
                onSubmit={iosForm.handleSubmit(onIosSignUpSubmit)}
              >
                <Input
                  className="flex-1"
                  registration={{
                    register: iosForm.register,
                    errors: iosForm.formState.errors,
                  }}
                  name="account"
                  options={{
                    required: t('please-enter-your-near-account', {
                      ns: 'universal',
                    }),
                  }}
                />
                <Button type="submit">
                  {t('ok', {
                    ns: 'universal',
                  })}
                </Button>
              </form>
            ),
            innerButtons: [
              {
                id: 'back',
                title: t('Back'),
                onClick: () => setCurrectStage(4),
              },
            ],
          },
        ]}
      />

      <GamePopup
        prefixId={prefixId}
        id="fin"
        active={currectStage == 5}
        onFinish={() => switchScene(3)}
        elements={[
          {
            id: 'fin',
            conversationImage: '/stemmy/e_head.png',
            title: t(
              'now-you-are-ready-to-fight-in-a-tournament-take-this-weapon-it-will-help-you-in-a-battle',
            ),

            externalbuttons: [
              {
                id: 'next',
                title: t('take-the-weapon'),
                isFinishOnClick: true,
              },
            ],
            windowSize: WidnowSize.Big,
          },
        ]}
      />
    </div>
  );
};

export default HerePart1Android;
