import {OrbitControls, useGLTF} from '@react-three/drei';
import {Canvas} from '@react-three/fiber';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  FaRegArrowAltCircleRight,
  FaRegArrowAltCircleLeft,
} from 'react-icons/fa';
import {Vector3, Euler} from 'three';
import {BodyModel} from '../../../types/models/BodyModel';
import {HeadModel} from '../../../types/models/HeadModel';
import Button from '../../form/button/Button';
import GameSound from '../effects/Sound';
import Character, {CharacterRefProps} from '../models/Character';
import {IntegrationSceneProps} from '../utils/IntegrationScene';

export interface ConstructorElement<T extends HeadModel | BodyModel> {
  model: T;
  address: string;
}

interface ConstructorSceneProps extends IntegrationSceneProps<unknown> {
  onSelected: (
    selectedHead?: ConstructorElement<HeadModel>,
    selectedBody?: ConstructorElement<BodyModel>,
  ) => void;

  enableSword?: boolean;
  head?: string;
  body?: string;
}

const ConstructorScene: React.FC<ConstructorSceneProps> = ({
  onSelected,
  head,
  body,
  enableSword = false,
}) => {
  const playerRef =
    useRef<CharacterRefProps>() as React.MutableRefObject<CharacterRefProps>;

  const {t} = useTranslation('universal');

  const [currentHead, setCurrentHead] = useState(0);
  const [heads] = useState<(ConstructorElement<HeadModel> | undefined)[]>([
    undefined,
    {
      model: useGLTF(
        'https://storage.googleapis.com/stemmy-integrations/models/character/AlienHead.glb',
      ) as unknown as HeadModel,
      address:
        'https://storage.googleapis.com/stemmy-integrations/models/character/AlienHead.glb',
    },
    {
      model: useGLTF(
        'https://storage.googleapis.com/stemmy-integrations/models/character/PaketHead.glb',
      ) as unknown as HeadModel,
      address:
        'https://storage.googleapis.com/stemmy-integrations/models/character/PaketHead.glb',
    },
    {
      model: useGLTF(
        'https://storage.googleapis.com/stemmy-integrations/models/character/ThiefHead.glb',
      ) as unknown as HeadModel,
      address:
        'https://storage.googleapis.com/stemmy-integrations/models/character/ThiefHead.glb',
    },
  ]);

  const [currentBody, setCurrentBody] = useState(0);
  const [bodies] = useState<(ConstructorElement<BodyModel> | undefined)[]>([
    undefined,
    {
      model: useGLTF(
        'https://storage.googleapis.com/stemmy-integrations/models/character/BearBody.glb',
      ) as unknown as BodyModel,
      address:
        'https://storage.googleapis.com/stemmy-integrations/models/character/BearBody.glb',
    },
    {
      model: useGLTF(
        'https://storage.googleapis.com/stemmy-integrations/models/character/NinjaBody.glb',
      ) as unknown as BodyModel,
      address:
        'https://storage.googleapis.com/stemmy-integrations/models/character/NinjaBody.glb',
    },
    {
      model: useGLTF(
        'https://storage.googleapis.com/stemmy-integrations/models/character/OfficalBody.glb',
      ) as unknown as BodyModel,
      address:
        'https://storage.googleapis.com/stemmy-integrations/models/character/OfficalBody.glb',
    },
  ]);

  useEffect(() => {
    if (body) {
      setCurrentBody(bodies.findIndex((x) => x?.address === body));
    }
    if (head) {
      setCurrentHead(heads.findIndex((x) => x?.address === head));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative w-full h-full">
      <div
        style={{
          backgroundImage: 'url("/backgrounds/constructor_bg.jpg")',
          backgroundSize: 'cover',
        }}
        className="absolute top-0 left-0 w-full h-full"
      >
        <Canvas>
          <GameSound
            src="https://storage.googleapis.com/stemmy-integrations/audio/bg_normal.mp3"
            autoplay
            loop
          />
          <ambientLight color="#fff" intensity={0.3} />
          <directionalLight
            position={[0, 15, 10]}
            intensity={0.5}
            castShadow
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
          />

          <Character
            ref={playerRef}
            scale={new Vector3(1.3, 1.3, 1.3)}
            position={new Vector3(0.25, -1.1, 0)}
            rotation={new Euler(0, 0, 0)}
            headModel={heads[currentHead]?.model}
            bodyModel={bodies[currentBody]?.model}
            enableSword={enableSword}
            // weaponModel={swordModel}
          />
          <OrbitControls
            enableZoom={false}
            maxAzimuthAngle={Math.PI / 4}
            maxPolarAngle={Math.PI / 2}
            minAzimuthAngle={-Math.PI / 4}
            minPolarAngle={Math.PI / 2}
          />
        </Canvas>
      </div>
      <div className="select-none absolute top-4 lg:top-12 left-0 right-0 mx-auto text-center font-bold text-white text-game-2xl">
        {t('customize-your-character')}
      </div>

      <div
        className="absolute  font-bold text-white text-game-2xl cursor-pointer"
        style={{top: '30%', left: '38%'}}
        onClick={() => {
          setCurrentHead((x) => (x + 1) % heads.length);
        }}
      >
        <FaRegArrowAltCircleLeft />
      </div>
      <div
        className="absolute  font-bold text-white text-game-2xl cursor-pointer"
        style={{top: '50%', left: '38%'}}
        onClick={() => {
          setCurrentBody((x) => (x + 1) % bodies.length);
        }}
      >
        <FaRegArrowAltCircleLeft />
      </div>
      <div
        className="absolute  font-bold text-white text-game-2xl cursor-pointer"
        style={{top: '30%', left: '63%'}}
        onClick={() => {
          setCurrentHead((x) => {
            const num = (x - 1) % heads.length;
            return num < 0 ? heads.length - 1 : num;
          });
        }}
      >
        <FaRegArrowAltCircleRight />
      </div>
      <div
        className="absolute  font-bold text-white text-game-2xl cursor-pointer"
        style={{top: '50%', left: '63%'}}
        onClick={() => {
          setCurrentBody((x) => {
            const num = (x - 1) % bodies.length;
            return num < 0 ? bodies.length - 1 : num;
          });
        }}
      >
        <FaRegArrowAltCircleRight />
      </div>

      <div
        className="absolute bottom-10 right-10"
        style={{bottom: '5%', right: '5%'}}
      >
        <Button
          className="text-game-xl"
          onClick={() => {
            onSelected(heads[currentHead], bodies[currentBody]);
          }}
        >
          To battle
        </Button>
      </div>
    </div>
  );
};

export default ConstructorScene;
