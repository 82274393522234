/* eslint-disable react-hooks/rules-of-hooks */
import {useGLTF} from '@react-three/drei';
import {useCallback, useEffect, useState} from 'react';
import {BsFullscreen, BsFullscreenExit} from 'react-icons/bs';
import {useSaveGame} from '../../api/Integration/save/SaveMutation';
import {queryClient} from '../../app/App';
import HerePart0 from '../../components/game/scenarios/HerePart0';
import HerePart1 from '../../components/game/scenarios/HerePart1';
import HerePart1Android from '../../components/game/scenarios/HerePart1Android';
import HerePart2 from '../../components/game/scenarios/HerePart2';
import HereTournament from '../../components/game/scenarios/HereTournament';
import HereTournamentTable from '../../components/game/scenarios/HereTournamentTable';
import ChestScene from '../../components/game/scenes/ChestOpenScene';
import ConstructorScene, {
  ConstructorElement,
} from '../../components/game/scenes/ConstructorScene';
import IntegrationScene from '../../components/game/utils/IntegrationScene';
import {
  AuthData,
  GetAuthDataKey,
  useGameAuthData,
} from '../../services/gameAuth/AuthProvider';
import {BodyModel} from '../../types/models/BodyModel';
import {HeadModel} from '../../types/models/HeadModel';
import {HereIntegrationSave} from '../../types/save/HereIntegrationSave';

interface HereScenarioProps {
  loadData?: HereIntegrationSave;
}

const HereScenario: React.FC<HereScenarioProps> = ({loadData}) => {
  const [currentLoadedData, setLoadData] = useState(loadData);

  const getAuth = useGameAuthData();

  const [currentScene, setCurrentScene] = useState(
    loadData?.scene === undefined ? -1 : loadData.scene,
  );

  const [headModel, setHeadModel] = useState<
    ConstructorElement<HeadModel> | undefined
  >(
    currentLoadedData?.character?.head
      ? {
          model: useGLTF(
            currentLoadedData.character.head,
          ) as unknown as HeadModel,
          address: currentLoadedData.character.head,
        }
      : undefined,
  );
  const [bodyModel, setBodyModel] = useState<
    ConstructorElement<BodyModel> | undefined
  >(
    currentLoadedData?.character?.body
      ? {
          model: useGLTF(
            currentLoadedData.character.body,
          ) as unknown as BodyModel,
          address: currentLoadedData.character.body,
        }
      : undefined,
  );

  const saveData = useSaveGame();

  const saveScene = useCallback(
    (data: HereIntegrationSave, userId: string) => {
      saveData.mutate({
        userId: userId,
        integrationId: '791A5C0E-65CA-4284-BB3B-B55F3CEC705A',
        saveData: JSON.stringify(data),
      });
    },
    [saveData],
  );

  const PartialSaver = useCallback(
    (save?: Partial<HereIntegrationSave>, userId?: string) => {
      console.log('Loaded data: =======');
      console.log(currentLoadedData);
      const data: HereIntegrationSave = {
        ...currentLoadedData,
        scene: currentLoadedData?.scene || currentScene,
        character: {
          head: headModel?.address,
          body: bodyModel?.address,
        },
        ...save,
      };
      setLoadData(data);
      saveScene(
        data,
        getAuth.data?.id ||
          queryClient.getQueryData<AuthData>([GetAuthDataKey])?.id ||
          userId ||
          '',
      );
    },
    [
      getAuth,
      bodyModel?.address,
      currentLoadedData,
      currentScene,
      headModel?.address,
      saveScene,
    ],
  );

  const switchScene = useCallback(
    (scene: number, save?: Partial<HereIntegrationSave>) => {
      PartialSaver({
        scene: scene,
        ...save,
      });
      setCurrentScene(scene);
    },
    [PartialSaver],
  );

  const switchSceneWitoutSave = useCallback((scene: number) => {
    setCurrentScene(scene);
  }, []);

  const [isOpenedFullScreen, setIsOpenedFullScreen] = useState(false);
  useEffect(() => {
    document.documentElement.onfullscreenchange = () => {
      setIsOpenedFullScreen(!!document.fullscreenElement);
    };
  }, []);

  return (
    <>
      <div
        className="w-full h-full aspect-video relative overflow-hidden text-game-base max-h-screen"
        style={{
          backgroundColor: '#000',
          backgroundSize: 'cover',
        }}
      >
        <div className="absolute bottom-3 left-3 z-20 text-white text-game-sm">
          Powered by Stemmy
        </div>
        {/* <div
          className="bg-gray-300 rounded-lg p-2 aspect-square absolute bottom-3 right-3 cursor-pointer z-20"
          style={{maxWidth: '50px', width: '10%'}}
          onClick={() => {
            if (!isOpenedFullScreen) {
              document.documentElement.requestFullscreen();
              screen.orientation.lock('landscape');
            } else {
              document.exitFullscreen();
            }
          }}
        >
          {!isOpenedFullScreen ? (
            <BsFullscreen className="w-full h-full" />
          ) : (
            <BsFullscreenExit className="w-full h-full" />
          )}
        </div> */}

        {currentScene == -1 && (
          <IntegrationScene id="intro" isActive={currentScene == -1}>
            <HerePart0
              switchScene={switchScene}
              switchSceneWithoutSave={switchSceneWitoutSave}
              save={PartialSaver}
              loadData={currentLoadedData}
            />
          </IntegrationScene>
        )}

        {currentScene == 10 && (
          <IntegrationScene id="here_android" isActive={currentScene == 10}>
            <HerePart1Android
              switchScene={switchScene}
              switchSceneWithoutSave={switchSceneWitoutSave}
              save={PartialSaver}
              loadData={currentLoadedData}
              headModel={headModel?.model}
              bodyModel={bodyModel?.model}
            />
          </IntegrationScene>
        )}

        {currentScene == 0 && (
          <IntegrationScene id="here1" isActive={currentScene == 0}>
            <HerePart1
              switchScene={switchScene}
              switchSceneWithoutSave={switchSceneWitoutSave}
              save={PartialSaver}
              loadData={currentLoadedData}
              headModel={headModel?.model}
              bodyModel={bodyModel?.model}
            />
          </IntegrationScene>
        )}

        {currentScene == 1 && (
          <IntegrationScene id="constructor1" isActive={currentScene == 1}>
            <ConstructorScene
              switchScene={switchScene}
              switchSceneWithoutSave={switchSceneWitoutSave}
              save={PartialSaver}
              loadData={currentLoadedData}
              head={headModel?.address}
              body={bodyModel?.address}
              onSelected={(head, body) => {
                setHeadModel(head);
                setBodyModel(body);
                switchScene(2, {
                  character: {
                    head: head?.address,
                    body: body?.address,
                  },
                });
              }}
            />
          </IntegrationScene>
        )}

        {currentScene == 2 && (
          <IntegrationScene id="here2" isActive={currentScene == 2}>
            <HerePart2
              switchScene={switchScene}
              switchSceneWithoutSave={switchSceneWitoutSave}
              save={PartialSaver}
              loadData={currentLoadedData}
              headModel={headModel?.model}
              bodyModel={bodyModel?.model}
            />
          </IntegrationScene>
        )}

        {currentScene == 3 && (
          <IntegrationScene id="chest1" isActive={currentScene == 3}>
            <ChestScene
              switchScene={switchScene}
              switchSceneWithoutSave={switchSceneWitoutSave}
              save={PartialSaver}
              loadData={currentLoadedData}
              onFinished={() => switchScene(4)}
            />
          </IntegrationScene>
        )}

        {currentScene == 4 && (
          <IntegrationScene id="constructor2" isActive={currentScene == 4}>
            <ConstructorScene
              head={headModel?.address}
              body={bodyModel?.address}
              switchScene={switchScene}
              switchSceneWithoutSave={switchSceneWitoutSave}
              save={PartialSaver}
              loadData={currentLoadedData}
              onSelected={(head, body) => {
                setHeadModel(head);
                setBodyModel(body);
                switchScene(5, {
                  character: {
                    head: head?.address,
                    body: body?.address,
                  },
                });
              }}
              enableSword
            />
          </IntegrationScene>
        )}

        {currentScene == 5 && (
          <IntegrationScene id="tournament1" isActive={currentScene == 5}>
            <HereTournament
              headModel={headModel?.model}
              bodyModel={bodyModel?.model}
              switchScene={switchScene}
              switchSceneWithoutSave={switchSceneWitoutSave}
              save={PartialSaver}
              loadData={currentLoadedData}
            />
          </IntegrationScene>
        )}

        {currentScene == 6 && (
          <IntegrationScene id="leaderboard" isActive={currentScene == 6}>
            <HereTournamentTable
              switchScene={switchScene}
              switchSceneWithoutSave={switchSceneWitoutSave}
              save={PartialSaver}
              loadData={currentLoadedData}
            />
          </IntegrationScene>
        )}
      </div>
    </>
  );
};

export default HereScenario;
