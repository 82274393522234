import {Canvas} from '@react-three/fiber';
import mixpanel from 'mixpanel-browser';
import {useCallback, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Vector3, Euler, BackSide} from 'three';
import {useCreateNewIntegrationUser} from '../../../api/Integration/user/UserMutations';
import i18n from '../../../app/locales/i18n';
import {
  useSetGameAuthData,
  useUpdateGameAuthData,
} from '../../../services/gameAuth/AuthProvider';
import {HereIntegrationSave} from '../../../types/save/HereIntegrationSave';
import Button, {ButtonStyle} from '../../form/button/Button';
import Input from '../../form/input/Input';
import GameSound, {GameSoundRefProps} from '../effects/Sound';
import GamePopup from '../mechanics/InformingSliderView/GamePopup';
import Character from '../models/Character';
import {IntegrationSceneProps} from '../utils/IntegrationScene';

interface UserRegisterForm {
  name: string;
}

interface UserPhone {
  phone: string;
}

const HerePart0: React.FC<IntegrationSceneProps<HereIntegrationSave>> = ({
  switchScene,
  prefixId = 'scene_not_set',
}) => {
  const setAuth = useSetGameAuthData();
  const updateAuth = useUpdateGameAuthData();

  const {t} = useTranslation(['herePart0', 'universal']);

  const introSound = useRef<GameSoundRefProps>(null);

  const [currectStage, setCurrectStage] = useState(0);
  const registerUser = useCreateNewIntegrationUser();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<UserRegisterForm>();
  const onUserRegisterSubmit = useCallback(
    (data: UserRegisterForm) => {
      registerUser.mutate(
        {
          name: data.name,
        },
        {
          onSuccess: (val) => {
            // auth.login(val);
            mixpanel.identify(val.id);
            setAuth.mutate({
              id: val.id,
              name: val.name,
            });
            setCurrectStage(1);
            // switchScene(0, val.id);
          },
        },
      );
    },
    [setAuth, registerUser],
  );

  const userPhoneRegister = useForm<UserPhone>({
    defaultValues: {
      phone: i18n.language == 'en' ? '+1' : '+91',
    },
  });

  const onUserPhoneRegisterSubmit = useCallback(
    (data: UserPhone) => {
      // save({phone: data.phone});

      // auth.setPhone(data.phone);
      updateAuth.mutate({
        phone: data.phone,
      });
      switchScene(0, {phone: data.phone});
    },
    [updateAuth, switchScene],
  );

  return (
    <div className="relative w-full h-full">
      <div
        style={{
          backgroundImage: 'url("/backgrounds/2.jpg")',
          backgroundSize: 'cover',
        }}
        className="absolute top-0 left-0 w-full h-full"
      >
        <Canvas
          shadows
          camera={{rotation: [-0.3, 0, 0], position: [0, 2.3, 5], fov: 60}}
        >
          {/* <GameSound src="audio/bg_normal.mp3" autoplay loop /> */}

          <ambientLight color="#fff" intensity={0.3} />
          <directionalLight
            position={[0, 15, 10]}
            intensity={0.5}
            castShadow
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
          />

          <Character
            scale={new Vector3(1, 1, 1)}
            position={new Vector3(-3.8, -1, 0)}
            rotation={new Euler(0, 0.5, 0)}
          />

          <mesh
            position={[0, -1, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            receiveShadow
          >
            <planeBufferGeometry attach="geometry" args={[25, 15]} />
            <shadowMaterial
              attach="material"
              side={BackSide}
              color="#000"
              opacity={0.2}
              transparent
            />
          </mesh>

          <GameSound
            ref={introSound}
            src={
              i18n.language == 'en'
                ? 'https://storage.googleapis.com/stemmy-integrations/audio/intro_en.wav'
                : 'https://storage.googleapis.com/stemmy-integrations/audio/intro_in.wav'
            }
            volume={3}
          />
        </Canvas>
      </div>

      {/* =========================================== QUEST =========================================== */}

      <GamePopup
        prefixId={prefixId}
        id="intro"
        onFinish={() => setCurrectStage(1)}
        active={currectStage == 0}
        elements={[
          {
            id: 'hello',
            conversationImage: '/stemmy/e_head.png',
            title: t(
              'hello-im-one-of-the-organizers-of-the-tournament-place-where-you-can-learn-something-new-and-earn-money-get-into-the-top-50-and-earn-400-inr',
            ),
            text: t('powered-by-stemmy'),
            externalbuttons: [
              {
                id: 'start',
                title: t('start-the-game'),
                buttonStyle: ButtonStyle.Purple,
                className: 'text-game-xl',
                isNextOnClick: true,
                onClick: () => {
                  setTimeout(() => {
                    introSound.current?.play();
                  }, 200);
                },
              },
            ],
          },
          {
            id: 'name',
            conversationImage: '/stemmy/e_head.png',
            title: t('what-name'),
            horizontalElement: (
              <form
                className="flex w-full gap-2"
                onSubmit={handleSubmit(onUserRegisterSubmit)}
              >
                <Input
                  className="flex-1"
                  registration={{register, errors}}
                  name="name"
                  options={{
                    required: t('enter-your-name', {ns: 'universal'}),
                    maxLength: {
                      value: 15,
                      message: t('maximum-15-symbols', {ns: 'universal'}),
                    },
                    minLength: {
                      value: 3,
                      message: t('minimum-3-symbols', {ns: 'universal'}),
                    },
                  }}
                />
                <Button type="submit">{t('ok-0')}</Button>
              </form>
            ),
          },
        ]}
      />

      <GamePopup
        prefixId={prefixId}
        id="phone_form"
        active={currectStage == 1}
        elements={[
          {
            id: 'phone',
            conversationImage: '/stemmy/e_head.png',
            title: t('enter-your-phone-number-where-we-will-send-a-reward'),
            horizontalElement: (
              <form
                className="flex w-full mt-2 gap-2"
                onSubmit={userPhoneRegister.handleSubmit(
                  onUserPhoneRegisterSubmit,
                )}
              >
                <Input
                  className="flex-1"
                  registration={{
                    register: userPhoneRegister.register,
                    errors: userPhoneRegister.formState.errors,
                  }}
                  name="phone"
                  type="phone"
                  options={{
                    required: t('please-enter-your-phone-number', {
                      ns: 'universal',
                    }),
                    pattern: {
                      value: /^\+[1-9\(\)]\d{1,16}$/,
                      message: t('enter-in-format-88888888', {ns: 'universal'}),
                    },
                  }}
                />
                <Button type="submit">{t('ok', {ns: 'universal'})}</Button>
              </form>
            ),
          },
        ]}
      />
    </div>
  );
};

export default HerePart0;
