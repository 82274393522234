import {
  ButtonsLayout,
  GamePopupFillProps,
  WidnowSize,
} from '../components/game/mechanics/InformingSliderView/GamePopupElement';

export const getTournamentQuestions = (
  onQuestionAnswered: (val: boolean, onCompleted?: () => void) => void,
  finalCallback: (isCorrect: boolean) => void,
  locale: string,
) => {
  const tournemaentElementsIn: GamePopupFillProps[] = [
    {
      id: 'FEA0FB1D-7782-4FE6-979A-AEC29FD64DC1',
      title: 'दुनिया में सबसे पहली क्रिप्टो करेंसी कौन सी है?',
      windowSize: WidnowSize.Big,
      buttonsLayout: ButtonsLayout.TWO_COLUMNS,
      externalbuttons: [
        {
          id: '1',
          title: 'Litecoin (LTC)',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '2',
          title: 'Bitcoin (BTC)',
          clickDelay: 1000,
          isCoorrectAnswer: true,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(true),
        },
        {
          id: '3',
          title: 'Ravencoin (RVN)',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '4',
          title: 'Ethereum (ETH)',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
      ],
    },

    {
      id: 'F8DEB38F-6283-49AE-9E30-19147C4E0438',
      title: 'बिटकॉइन के निर्माता का छद्म नाम क्या है?',
      windowSize: WidnowSize.Big,
      buttonsLayout: ButtonsLayout.TWO_COLUMNS,
      externalbuttons: [
        {
          id: '1',
          title: 'John Johnson',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '2',
          title: 'Elon Musk',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '3',
          title: 'George Washington',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '4',
          title: 'Satoshi Nakamoto',
          clickDelay: 1000,
          isCoorrectAnswer: true,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(true),
        },
      ],
    },

    // {
    //   id: '0148FA37-0D11-48F7-8CDB-DD7D52A6F0C0',
    //   title:
    //     '"बिटकॉइन: ए पीयर-टू-पीयर इलेक्ट्रॉनिक कैश सिस्टम" लेख कब प्रकाशित हुआ था?',
    //   windowSize: WidnowSize.Big,
    //   buttonsLayout: ButtonsLayout.TWO_COLUMNS,
    //   externalbuttons: [
    //     {
    //       title: '2005 में',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: true,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(true),
    //     },
    //     {
    //       title: '2008 में',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: '1998 में',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: '2011 में',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //   ],
    // },

    {
      id: '3D40EF0F-DCC6-4414-872A-BEB926C32324',
      title: 'क्रिप्टोक्यूरेंसी किस तकनीक पर आधारित है?',
      windowSize: WidnowSize.Big,
      buttonsLayout: ButtonsLayout.TWO_COLUMNS,
      externalbuttons: [
        {
          id: '1',
          title: 'सुरक्षा सेवा प्रौद्योगिकियां',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '2',
          title: 'प्रकाश मापन',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '3',
          title: 'ब्लॉकचेन',
          clickDelay: 1000,
          isCoorrectAnswer: true,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(true),
        },
        {
          id: '4',
          title: 'क्रिप्टएनालिसिस',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
      ],
    },

    {
      id: '5AAC3840-FA0D-461F-8807-B851ED3FA7D9',
      title: 'क्रिप्टोक्यूरेंसी उत्पादन का नाम क्या है?',
      windowSize: WidnowSize.Big,
      buttonsLayout: ButtonsLayout.TWO_COLUMNS,
      externalbuttons: [
        {
          id: '1',
          title: 'खुदाई',
          clickDelay: 1000,
          isCoorrectAnswer: true,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(true),
        },
        {
          id: '2',
          title: 'पूरा करना',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '3',
          title: 'उत्पाद',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '4',
          title: 'निष्कर्षण',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
      ],
    },

    {
      id: '5E4EA38D-D2BE-4E1B-B0BE-5EF78A64DA53',
      title: 'किस क्रिप्टोकरेंसी का बाजार पूंजीकरण सबसे अधिक है?',
      windowSize: WidnowSize.Big,
      buttonsLayout: ButtonsLayout.TWO_COLUMNS,
      externalbuttons: [
        {
          id: '1',
          title: 'डॉलर',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false, () => finalCallback(false)),
        },
        {
          id: '2',
          title: 'Ethereum',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false, () => finalCallback(false)),
        },
        {
          id: '3',
          title: 'USDT',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false, () => finalCallback(false)),
        },
        {
          id: '4',
          title: 'Bitcoin',
          clickDelay: 1000,
          isCoorrectAnswer: true,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(true, () => finalCallback(true)),
        },
      ],
    },

    // {
    //   id: '33AACBCF-BC85-4268-931A-60106B70D41A',
    //   title: 'बीटीसी के साथ खरीदी गई पहली मूर्त वस्तु क्या थी?',
    //   windowSize: WidnowSize.Big,
    //   buttonsLayout: ButtonsLayout.TWO_COLUMNS,
    //   externalbuttons: [
    //     {
    //       title: 'बीयर',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: 'पिज़्ज़ा',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: true,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(true),
    //     },
    //     {
    //       title: 'एक कार',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: 'एक विमान',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //   ],
    // },

    // {
    //   id: '89F9142E-11CE-43BC-8E95-A6B2F48637DC',
    //   title: 'कुल कितने बिटकॉइन हो सकते हैं?',
    //   windowSize: WidnowSize.Big,
    //   buttonsLayout: ButtonsLayout.TWO_COLUMNS,
    //   externalbuttons: [
    //     {
    //       title: '20 मिलियन',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: '21 मिलियन',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: true,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(true),
    //     },
    //     {
    //       title: '84 मिलियन',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: '100 हजार',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //   ],
    // },

    // {
    //   id: '9A1153B1-359C-4B5C-AEC5-DE11311C036C',
    //   title: 'बिटकॉइन नेटवर्क पर लेनदेन की कितनी पुष्टि सुरक्षित मानी जाती है?',
    //   windowSize: WidnowSize.Big,
    //   buttonsLayout: ButtonsLayout.TWO_COLUMNS,
    //   externalbuttons: [
    //     {
    //       title: '3',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: '1',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: '6',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: true,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(true),
    //     },
    //     {
    //       title: '7',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //   ],
    // },
    // {
    //   id: '292DC36C-1DBE-49AA-897D-6D99E224CCA2',
    //   title:
    //     'उस वॉलेट का नाम क्या है जिसे लेनदेन करने के लिए दो या दो से अधिक चाबियों की आवश्यकता होती है?',
    //   windowSize: WidnowSize.Big,
    //   buttonsLayout: ButtonsLayout.TWO_COLUMNS,
    //   externalbuttons: [
    //     {
    //       title: 'मल्टीसिग वॉलेट',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => {
    //         onQuestionAnswered(false, finalCallback);
    //       },
    //     },
    //     {
    //       title: 'मल्टी-वॉलेट',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => {
    //         onQuestionAnswered(false, finalCallback);
    //       },
    //     },
    //     {
    //       title: 'मल्टीकी वॉलेट',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: true,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => {
    //         onQuestionAnswered(true, finalCallback);
    //       },
    //     },
    //     {
    //       title: '2fa बटुआ',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => {
    //         onQuestionAnswered(false, finalCallback);
    //       },
    //     },
    //   ],
    // },
  ];

  const tournemaentElements: GamePopupFillProps[] = [
    {
      id: 'FEA0FB1D-7782-4FE6-979A-AEC29FD64DC1',
      title: 'Which cryptocurrency is the first in the world?',
      windowSize: WidnowSize.Big,
      buttonsLayout: ButtonsLayout.TWO_COLUMNS,
      externalbuttons: [
        {
          id: '1',
          title: 'Litecoin (LTC)',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '2',
          title: 'Bitcoin (BTC)',
          clickDelay: 1000,
          isCoorrectAnswer: true,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(true),
        },
        {
          id: '3',
          title: 'Ravencoin (RVN)',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '4',
          title: 'Ethereum (ETH)',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
      ],
    },

    {
      id: 'F8DEB38F-6283-49AE-9E30-19147C4E0438',
      title: 'What is the pseudonym of the creator of Bitcoin?',
      windowSize: WidnowSize.Big,
      buttonsLayout: ButtonsLayout.TWO_COLUMNS,
      externalbuttons: [
        {
          id: '1',
          title: 'John Johnson',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '2',
          title: 'Elon Musk',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '3',
          title: 'George Washington',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '4',
          title: 'Satoshi Nakamoto',
          clickDelay: 1000,
          isCoorrectAnswer: true,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(true),
        },
      ],
    },

    // {
    //   id: '0148FA37-0D11-48F7-8CDB-DD7D52A6F0C0',
    //   title:
    //     'When was the article «Bitcoin: A Peer-to-Peer Electronic Cash System» published?',
    //   windowSize: WidnowSize.Big,
    //   buttonsLayout: ButtonsLayout.TWO_COLUMNS,
    //   externalbuttons: [
    //     {
    //       title: 'in 2005',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: true,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(true),
    //     },
    //     {
    //       title: 'in 2008',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: 'in 1998',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: 'in 2011',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //   ],
    // },

    {
      id: '3D40EF0F-DCC6-4414-872A-BEB926C32324',
      title: 'What technology is the cryptocurrency based on?',
      windowSize: WidnowSize.Big,
      buttonsLayout: ButtonsLayout.TWO_COLUMNS,
      externalbuttons: [
        {
          id: '1',
          title: 'Security Service Technologies',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '2',
          title: 'Photometry',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '3',
          title: 'Blockchain',
          clickDelay: 1000,
          isCoorrectAnswer: true,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(true),
        },
        {
          id: '4',
          title: 'Cryptanalysis',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
      ],
    },

    {
      id: '5AAC3840-FA0D-461F-8807-B851ED3FA7D9',
      title: 'What is the name of cryptocurrency production?',
      windowSize: WidnowSize.Big,
      buttonsLayout: ButtonsLayout.TWO_COLUMNS,
      externalbuttons: [
        {
          id: '1',
          title: 'Mining',
          clickDelay: 1000,
          isCoorrectAnswer: true,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(true),
        },
        {
          id: '2',
          title: 'Make-up',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '3',
          title: 'Product',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
        {
          id: '4',
          title: 'Extraction',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false),
        },
      ],
    },

    {
      id: '5E4EA38D-D2BE-4E1B-B0BE-5EF78A64DA53',
      title: 'Which cryptocurrency has the highest market capitalisation?',
      windowSize: WidnowSize.Big,
      buttonsLayout: ButtonsLayout.TWO_COLUMNS,
      externalbuttons: [
        {
          id: '1',
          title: 'Dollar',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false, () => finalCallback(false)),
        },
        {
          id: '2',
          title: 'Ethereum',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false, () => finalCallback(false)),
        },
        {
          id: '3',
          title: 'USDT',
          clickDelay: 1000,
          isCoorrectAnswer: false,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(false, () => finalCallback(false)),
        },
        {
          id: '4',
          title: 'Bitcoin',
          clickDelay: 1000,
          isCoorrectAnswer: true,
          isNextOnClick: true,
          className: 'text-game-lg',
          onClick: () => onQuestionAnswered(true, () => finalCallback(true)),
        },
      ],
    },

    // {
    //   id: '33AACBCF-BC85-4268-931A-60106B70D41A',
    //   title: 'What was the first tangible item purchased with BTC?',
    //   windowSize: WidnowSize.Big,
    //   buttonsLayout: ButtonsLayout.TWO_COLUMNS,
    //   externalbuttons: [
    //     {
    //       title: 'Beer',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: 'Pizza',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: true,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(true),
    //     },
    //     {
    //       title: 'A car',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: 'A plane',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //   ],
    // },

    // {
    //   id: '89F9142E-11CE-43BC-8E95-A6B2F48637DC',
    //   title: 'How many Bitcoins can there be in total?',
    //   windowSize: WidnowSize.Big,
    //   buttonsLayout: ButtonsLayout.TWO_COLUMNS,
    //   externalbuttons: [
    //     {
    //       title: '20 million',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: '21 million',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: true,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(true),
    //     },
    //     {
    //       title: '84 million',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: '100 thousand',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //   ],
    // },

    // {
    //   id: '9A1153B1-359C-4B5C-AEC5-DE11311C036C',
    //   title:
    //     'How many confirmations of a transaction is considered secure on the Bitcoin network?',
    //   windowSize: WidnowSize.Big,
    //   buttonsLayout: ButtonsLayout.TWO_COLUMNS,
    //   externalbuttons: [
    //     {
    //       title: '3',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: '1',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //     {
    //       title: '6',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: true,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(true),
    //     },
    //     {
    //       title: '7',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => onQuestionAnswered(false),
    //     },
    //   ],
    // },
    // {
    //   id: '292DC36C-1DBE-49AA-897D-6D99E224CCA2',
    //   title:
    //     'What is the name of a wallet that requires two or more keys to create a transaction?',
    //   windowSize: WidnowSize.Big,
    //   buttonsLayout: ButtonsLayout.TWO_COLUMNS,
    //   externalbuttons: [
    //     {
    //       title: 'Multisig Wallet',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => {
    //         onQuestionAnswered(false, finalCallback);
    //       },
    //     },
    //     {
    //       title: 'Multi-wallet',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => {
    //         onQuestionAnswered(false, finalCallback);
    //       },
    //     },
    //     {
    //       title: 'Multikey wallet',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: true,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => {
    //         onQuestionAnswered(true, finalCallback);
    //       },
    //     },
    //     {
    //       title: '2fa wallet',
    //       clickDelay: 1000,
    //       isCoorrectAnswer: false,
    //       isNextOnClick: true,
    //       className: 'text-game-lg',
    //       onClick: () => {
    //         onQuestionAnswered(false, finalCallback);
    //       },
    //     },
    //   ],
    // },
  ];
  return locale == 'in' ? tournemaentElementsIn : tournemaentElements;
};
