import classNames from 'classnames';

interface AndroidPhone extends React.HTMLProps<HTMLDivElement> {
  image: string;
}

const AndroidPhone: React.FC<AndroidPhone> = ({image, className, ...rest}) => (
  <div className={classNames('relative aspect-android', className)} {...rest}>
    <img
      src="/here/android/AndroidSim.png"
      className="w-full absolute top-0 z-10"
    />
    <img
      src={image}
      className="absolute"
      style={{width: '93%', left: '2.7%', top: '4%'}}
    />
  </div>
);

export default AndroidPhone;
