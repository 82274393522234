import {animated, config, useSpring} from '@react-spring/three';
import {Canvas} from '@react-three/fiber';
import {useRef, useState, useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Vector3, Euler, BackSide} from 'three';
import {useTournamentRegister} from '../../../api/hereIntegration/HereIntegration';
import {useSetTournamentData} from '../../../api/Integration/tournament/TournamentMutations';
import i18n from '../../../app/locales/i18n';
import {getTournamentQuestions} from '../../../data/hereTournament';
import {useGameAuthData} from '../../../services/gameAuth/AuthProvider';
import {BodyModel} from '../../../types/models/BodyModel';
import {HeadModel} from '../../../types/models/HeadModel';
import {HereIntegrationSave} from '../../../types/save/HereIntegrationSave';
import Button, {ButtonStyle} from '../../form/button/Button';
import Input from '../../form/input/Input';
import HitEffect, {HitEffectRefProps} from '../effects/HitEffect';
import GameSound, {GameSoundRefProps} from '../effects/Sound';
import GamePopup from '../mechanics/InformingSliderView/GamePopup';
import InformingView from '../mechanics/InformingSliderView/InformingView';
import CactusEnimy from '../models/Cactus';
import Character, {CharacterRefProps} from '../models/Character';
import {EnimyRefProps} from '../models/Enimy';
import Fireball, {FireballRefProps} from '../models/Fireball';
import BattleInfo, {BattleInfoRefProps, Orientation} from '../ui/BattleInfo';
import BlackBackground from '../utils/BlackBackground';
import {IntegrationSceneProps} from '../utils/IntegrationScene';
import {FailType} from './HerePart2';

interface HereTournamentProps
  extends IntegrationSceneProps<HereIntegrationSave> {
  headModel?: HeadModel;
  bodyModel?: BodyModel;
}

interface UserPhone {
  phone: string;
}

interface TournamentResults {
  time: number;
  correctAnswers: number;
}

const HereTournament: React.FC<HereTournamentProps> = ({
  headModel,
  bodyModel,
  loadData,
  switchScene,
  prefixId = 'scene_not_set',
}) => {
  const [currectStage, setCurrectStage] = useState(-1);
  const {t} = useTranslation(['hereTournamnet', 'universal']);

  const fireballSoundRef = useRef<GameSoundRefProps>(null);
  const successSound = useRef<GameSoundRefProps>(null);
  const wrongSound = useRef<GameSoundRefProps>(null);

  const bgFightAudio = useRef<GameSoundRefProps>(null);
  const bgNormalAudio = useRef<GameSoundRefProps>(null);

  const tournamentRegister = useTournamentRegister();
  const [tournamnetResults, setTournamentResults] =
    useState<TournamentResults>();

  const getAuth = useGameAuthData();

  const [isFailedChallange, setIsFailedChallange] = useState<FailType>(
    FailType.None,
  );

  const playerRef =
    useRef<CharacterRefProps>() as React.MutableRefObject<CharacterRefProps>;

  const enimyRef =
    useRef<EnimyRefProps>() as React.MutableRefObject<EnimyRefProps>;

  const hitPlayerEffectRef =
    useRef<HitEffectRefProps>() as React.MutableRefObject<HitEffectRefProps>;

  const hitEnimyEffectRef =
    useRef<HitEffectRefProps>() as React.MutableRefObject<HitEffectRefProps>;

  const fireballAnimation =
    useRef<FireballRefProps>() as React.MutableRefObject<FireballRefProps>;

  const PlayerBattleInfo =
    useRef<BattleInfoRefProps>() as React.MutableRefObject<BattleInfoRefProps>;
  const EnimyBattleInfo =
    useRef<BattleInfoRefProps>() as React.MutableRefObject<BattleInfoRefProps>;

  const [isQuestionsHidden, setIsQuestionsHidden] = useState(false);

  const [isLaunched, setIsLaunched] = useState(false);
  const [isFireballVisible, setIsFireballVisible] = useState(false);

  const {fireballPosition} = useSpring({
    fireballPosition: !isLaunched ? -2.5 : 3,
    config: config.stiff,
  });

  const castSpellAnimation = useCallback(() => {
    setIsFireballVisible(true);
    fireballAnimation.current?.spawn();
    fireballSoundRef.current?.play();
    setTimeout(() => {
      setIsLaunched(true);
    }, 2500);
    setTimeout(() => {
      setIsFireballVisible(false);
      setIsLaunched(false);

      enimyRef.current?.getDamage();
      hitEnimyEffectRef.current?.hitAnimation();
    }, 2800);
  }, [enimyRef, fireballAnimation, hitEnimyEffectRef]);

  const [question1CorrectAnswers, setQuestion1CorrectAnswers] = useState(0);
  const [question1Timer, setQuestion1Timer] = useState<number>(0);

  const onQuestionAnswered = useCallback(
    (isCorrect: boolean, onCompleted?: () => void) => {
      if (isCorrect) setQuestion1CorrectAnswers((x) => x + 1);

      if (isCorrect) {
        successSound.current?.play();
      } else {
        wrongSound.current?.play();
      }

      setIsQuestionsHidden(true);
      setTimeout(() => {
        if (isCorrect) {
          playerRef.current?.attack();

          if (Math.random() < 0.33) {
            // PlayerBattleInfo.current?.changeEnergy(-5);
            castSpellAnimation();
            setTimeout(() => {
              EnimyBattleInfo.current?.changeHealth(-1);
            }, 3000);

            setTimeout(() => {
              setIsQuestionsHidden(false);
              if (onCompleted) onCompleted();
            }, 4000);
          } else {
            // PlayerBattleInfo.current?.changeEnergy(-1);

            setTimeout(() => {
              enimyRef.current?.getDamage();
              hitEnimyEffectRef.current?.hitAnimation();
              EnimyBattleInfo.current.changeHealth(-1);

              setTimeout(() => {
                setIsQuestionsHidden(false);
                if (onCompleted) onCompleted();
              }, 800);
            }, 400);
          }
        } else {
          enimyRef.current?.attack();

          // EnimyBattleInfo.current?.changeEnergy(-1);

          setTimeout(() => {
            playerRef.current?.getDamage();
            hitPlayerEffectRef.current?.hitAnimation();
            PlayerBattleInfo.current.changeHealth(-1);

            setTimeout(() => {
              setIsQuestionsHidden(false);
              if (onCompleted) onCompleted();
            }, 800);
          }, 400);
        }
      }, 800);
    },
    [castSpellAnimation],
  );

  const [timeLeft, setTimeLeft] = useState(61);

  const SetResults = useCallback(() => {
    const currentTime = new Date().getTime();
    console.log('==================================');
    console.log(currentTime);
    console.log(question1Timer);
    const totalTime = (new Date().getTime() - question1Timer) / 1000;
    const totalCorrect = question1CorrectAnswers;

    setTournamentResults({
      time: totalTime,
      correctAnswers: totalCorrect,
    });

    setCurrectStage(2);
  }, [question1CorrectAnswers, question1Timer]);

  useEffect(() => {
    if (timeLeft == 61 || !!tournamnetResults) return;

    if (timeLeft <= 0 && !tournamnetResults) {
      SetResults();
      return;
    }

    setTimeout(() => {
      setTimeLeft((x) => {
        const newVal = x - 1;
        PlayerBattleInfo.current?.setEnergy(newVal);
        EnimyBattleInfo.current?.setEnergy(newVal);

        return newVal;
      });
    }, 1000);
  }, [timeLeft]);

  const setStageWithDelay = useCallback((stage: number, delay: number) => {
    setTimeout(() => {
      setCurrectStage(stage);
    }, delay);
  }, []);

  useEffect(() => {
    setStageWithDelay(0, 2000);
  }, [setStageWithDelay]);

  const userRegister = useForm<UserPhone>({
    defaultValues: {
      phone: getAuth.data?.phone,
    },
  });

  const tournament = useSetTournamentData();

  const onUserRegisterSubmit = useCallback(
    (data: UserPhone) => {
      if (!tournamnetResults) return;

      const value = Math.round(
        tournamnetResults.correctAnswers * 300 * 0.6 +
          (10000 / tournamnetResults.time) * 0.4,
      );

      console.log('==========================================');
      console.log(`Val: ${value}`);
      console.log(`Time: ${tournamnetResults.time}`);
      console.log(`AnswRes: ${tournamnetResults.correctAnswers * 300 * 0.6}`);
      console.log(`TimeRes: ${(10000 / tournamnetResults.time) * 0.4}`);

      tournament.mutate({
        tournamentId: 'CE2AD246-F4B8-4010-841D-3626B7218392',
        userId: getAuth.data?.id || `unset-${new Date().getTime()}`,
        result: value,
        metadata: JSON.stringify({
          time: tournamnetResults.time,
          correctAnsers: tournamnetResults.correctAnswers,
        }),
      });

      tournamentRegister.mutate(
        {
          accountName: loadData?.nearAccount || 'unset',
          isAndroid: loadData?.isAndroid || false,
          phone: data.phone,
          score: value,
        },
        {
          onSuccess: () => {
            switchScene(6);
          },
        },
      );
    },
    [
      getAuth,
      loadData?.isAndroid,
      loadData?.nearAccount,
      switchScene,
      tournament,
      tournamentRegister,
      tournamnetResults,
    ],
  );

  return (
    <div className="relative w-full h-full">
      <div
        style={{
          backgroundImage: 'url("/backgrounds/1.jpg")',
          backgroundSize: 'cover',
        }}
        className="absolute top-0 left-0 w-full h-full"
      >
        <Canvas
          shadows
          camera={{rotation: [-0.3, 0, 0], position: [0, 2.3, 5], fov: 60}}
        >
          <GameSound
            ref={fireballSoundRef}
            src="https://storage.googleapis.com/stemmy-integrations/sfx/Fireball.wav"
          />

          <GameSound
            ref={successSound}
            src="https://storage.googleapis.com/stemmy-integrations/sfx/answerSuccess.wav"
          />
          <GameSound
            ref={wrongSound}
            src="https://storage.googleapis.com/stemmy-integrations/sfx/answerWrong.mp3"
          />

          <GameSound
            ref={bgFightAudio}
            src="https://storage.googleapis.com/stemmy-integrations/audio/bg_fight.mp3"
            loop
          />
          <GameSound
            ref={bgNormalAudio}
            src="https://storage.googleapis.com/stemmy-integrations/audio/bg_normal.mp3"
            autoplay
            loop
          />

          <ambientLight color="#fff" intensity={0.3} />
          <directionalLight
            position={[0, 15, 10]}
            intensity={0.5}
            castShadow
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
          />
          <HitEffect
            ref={hitPlayerEffectRef}
            position={new Vector3(-2.8, 1, 1)}
            audioSrc="https://storage.googleapis.com/stemmy-integrations/sfx/CombatPunch2.wav"
          />
          <HitEffect
            ref={hitEnimyEffectRef}
            position={new Vector3(3, 1, 1)}
            audioSrc="https://storage.googleapis.com/stemmy-integrations/sfx/Punch1.wav"
          />
          <Character
            ref={playerRef}
            scale={new Vector3(1, 1, 1)}
            position={new Vector3(-3.8, -1, 0)}
            rotation={new Euler(0, 1.5, 0)}
            headModel={headModel}
            bodyModel={bodyModel}
            // weaponModel={swordModel}
            enableSword
          />
          <CactusEnimy
            ref={enimyRef}
            scale={new Vector3(1.5, 1.5, 1.5)}
            position={new Vector3(3.8, -1, 0)}
            rotation={new Euler(0, -1.4, 0)}
          />

          {/* to - (3); from - (-2.5) */}
          <animated.group
            position-x={fireballPosition}
            visible={isFireballVisible}
          >
            <Fireball ref={fireballAnimation} />
          </animated.group>

          <mesh
            position={[0, -1, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            receiveShadow
          >
            <planeBufferGeometry attach="geometry" args={[25, 15]} />
            <shadowMaterial
              attach="material"
              side={BackSide}
              color="#000"
              opacity={0.2}
              transparent
            />
          </mesh>
        </Canvas>
      </div>
      <BattleInfo
        avatarSrc="/stemmy/p_head.png"
        orientation={Orientation.LEFT}
        totalHealth={5}
        totalEnergy={61}
        properties={[]}
        ref={PlayerBattleInfo}
        isClock
      />
      <BattleInfo
        avatarSrc="/stemmy/e_head.png"
        orientation={Orientation.RIGHT}
        totalHealth={5}
        totalEnergy={61}
        properties={[]}
        ref={EnimyBattleInfo}
        isClock
      />

      {/* =========================================== QUEST =========================================== */}

      {!tournamnetResults && (
        <div className="text-game-xl absolute z-20 text-white left-1/2 top-2 md:top-5">
          {timeLeft}s
        </div>
      )}
      {isFailedChallange !== FailType.None && (
        <BlackBackground className="z-20">
          <div className="absolute z-30 w-full h-full flex flex-col justify-center items-center">
            <InformingView>
              <div className="flex flex-col gap-3 my-2">
                <div className="font-bold text-game-lg">
                  {isFailedChallange === FailType.QuestFailed && (
                    <span>
                      {t('you-should-get-at-least-10-near-on-your-account', {
                        ns: 'universal',
                      })}
                    </span>
                  )}
                  {isFailedChallange === FailType.IncorrectUsername && (
                    <span>
                      {t(
                        'it-looks-like-the-account-with-the-username-you-just-entered-doesnt-exist',
                        {ns: 'universal'},
                      )}
                    </span>
                  )}
                </div>
                <Button onClick={() => setIsFailedChallange(FailType.None)}>
                  {t('try-again-1', {ns: 'universal'})}
                </Button>
              </div>
            </InformingView>
          </div>
        </BlackBackground>
      )}

      <GamePopup
        prefixId={prefixId}
        id="intro"
        active={currectStage == 0}
        onFinish={() => setCurrectStage(1)}
        elements={[
          {
            id: 'intro',
            conversationImage: '/stemmy/e_head.png',
            title: t('welcome-to-the-tournament-champion'),
            text: t(
              'you-will-fight-against-the-furious-warrior-of-the-blockchain-guild-good-luck',
            ),

            externalbuttons: [
              {
                id: 'next',
                title: t('lets-go-1'),
                buttonStyle: ButtonStyle.Purple,
                className: 'text-game-xl',
                isNextOnClick: true,
              },
            ],
          },
        ]}
      />

      {currectStage === 1 && (
        <GamePopup
          prefixId={prefixId}
          id="tournament1"
          active
          onStart={() => {
            bgNormalAudio.current?.stop();
            bgFightAudio.current?.play();
            setQuestion1Timer(new Date().getTime());
            setTimeLeft(60);
          }}
          tempHidden={isQuestionsHidden}
          elements={getTournamentQuestions(
            onQuestionAnswered,
            (isCorrect: boolean) => {
              const totalTime = (new Date().getTime() - question1Timer) / 1000;
              const totalCorrect =
                question1CorrectAnswers + (isCorrect ? 1 : 0);

              console.log('=========');
              console.log(totalTime);
              console.log(totalCorrect);
              setTournamentResults({
                time: totalTime,
                correctAnswers: totalCorrect,
              });

              // alert(question1CorrectAnswers);
              setCurrectStage(2);
            },
            i18n.language,
          )}
        />
      )}

      <GamePopup
        prefixId={prefixId}
        id="phone_form"
        active={currectStage == 2}
        onStart={() => {
          bgNormalAudio.current?.play();
          bgFightAudio.current?.stop();
          setQuestion1Timer(new Date().getTime());
        }}
        elements={[
          {
            id: 'phone_form',
            conversationImage: '/stemmy/e_head.png',
            title: t('enter-your-phone-number-where-we-will-send-a-reward'),
            horizontalElement: (
              <form
                className="flex w-full mt-2 gap-2"
                onSubmit={userRegister.handleSubmit(onUserRegisterSubmit)}
              >
                <Input
                  className="flex-1"
                  registration={{
                    register: userRegister.register,
                    errors: userRegister.formState.errors,
                  }}
                  name="phone"
                  type="phone"
                  options={{
                    required: t('please-enter-your-phone-number', {
                      ns: 'universal',
                    }),
                    pattern: {
                      value: /^\+[1-9\(\)]\d{1,16}$/,
                      message: t('enter-in-format-88888888', {ns: 'universal'}),
                    },
                  }}
                />
                <Button type="submit">{t('ok', {ns: 'universal'})}</Button>
              </form>
            ),
          },
        ]}
      />
    </div>
  );
};

export default HereTournament;
